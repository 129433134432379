import styled from "styled-components";

import { colors, fontSizes } from "./Tokens";

export const Title = styled.h1`
  font-size: ${fontSizes.big};
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
  color: ${({ color }) => colors[color]};

  strong {
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
  }
`;

export const SubTitle = styled.div`
  font-size: inherit;
  font-weight: 700;
  color: ${colors.pink};
  width: 100%;
  max-width:100%;
  overflow:hidden;
  text-overflow:ellipsis;
`;

export const SmallTitle = styled.div`
  font-size: ${fontSizes.small};
  font-weight: 700;
  color: inherit;
  width: 100%;
`;
