import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { colors, fontSizes } from "./Tokens";
import { apiClient } from "../utils/apiClient";

const StyledFilterPanel = styled.div`
  width: 100%;
  display: fixed;
  color: ${colors.white};
  padding-bottom: 2rem;
  position: relative;

  top: 6.1rem;
`;

const PanelTitle = styled.h2`
  height: 6rem;
  display: flex;
  align-items: center;
  overflow: visible;
  color: inherit;
  margin: 0;
  font-size: ${fontSizes.xsmall};

  small {
    font-size: ${fontSizes.xxsmall};
    font-weight: 400;
  }
`;

const FilterGroup = styled.div`
  transition: height 0.3s;
  overflow: hidden;
`;

const FilterItem = styled.div`
  padding: 2px 0;
  min-height: 2.4rem;
  display: flex;
  align-items: center;
  white-space: nowrap;

  label,
  input {
    cursor: pointer;
  }

  input:hover + label {
    color: ${colors.purple};
  }

  label {
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: ${colors.purple};
    }
  }
`;

const SeeMore = styled.div`
  text-decoration: underline;
  text-underline-position: under;
  font-size: ${fontSizes.xxsmall};
  cursor: pointer;

  &:hover {
    color: ${colors.purple};
  }
`;

const PanelBody = styled.div`
  padding: 0 2rem 2rem;
  font-size: ${fontSizes.xsmall};
  background-color: ${colors.pink};
  margin-bottom: 10rem;
  border-radius: 4px;

  input[type="checkbox"] {
    margin: 0;

    + label {
      padding-left: 8px;
    }
  }
`;

const FilterPanel = ({ title, button, searchTerm, state, ...props }) => {
  const [brands, setBrands] = useState([]);
  //const [cats, setCats] = useState([]);

  const brandRef = useRef(null);
  //const catsRef = useRef(null);

  const [allBrands, toggleAllBrands] = useState(false);
  //const [allCats, toggleAllCats] = useState(false);

  const capitalise = (input) => input.charAt(0) + input.slice(1).toLowerCase();

  const getAllOptions = (toggle, elmRef, callback) => {
    if (toggle) {
      elmRef.current.style.height = brands.length * 24 + "px";
    } else {
      elmRef.current.style.height = 24 * 24 + "px";
      window.scrollTo(0, 0);
    }
    callback(toggle);
  };

  useEffect(() => {
    async function getBrands() {
      const brandres = await apiClient.portalProducts.getBrands();
      const brandresult = await brandres;
      const brandsList = await brandresult.data.map((a) => {
        return a.merk;
      });
      setBrands(brandsList);
    }
    // async function getCats() {
    //   const catRes = await apiClient.portalProducts.getCats();
    //   const catResult = await catRes;
    //   const catList = await catResult.data.map((a) => {
    //     return a.type;
    //   });
    //   setCats(catList);
    // }
    getBrands();
    //getCats();
  }, []);

  const isChecked = (e) => {
    props.addBrands(e.target.name);
  };
  // const isCheckedCat = (e) => {
  //   props.addCats(e.target.name);
  // };

  return (
    <StyledFilterPanel>
      <PanelBody>
        <PanelTitle>
          <span>
            Merken <small>({brands.length})</small>
          </span>
        </PanelTitle>
        <FilterGroup ref={brandRef}>
          {brands
            .sort((a, b) => a.localeCompare(b)) // Sort the brands alphabetically
            .map((row, index) => {
              if (index < 24 || allBrands) {
                // Always show first 24 or all if allBrands is true
                return (
                  <FilterItem key={index} title={row}>
                    <input
                      type="checkbox"
                      name={row}
                      id={row}
                      defaultValue={row}
                      onClick={(e) => {
                        isChecked(e);
                      }}
                    />
                    <label htmlFor={row}>{capitalise(row)}</label>
                  </FilterItem>
                );
              } else {
                return null; // Don't render if condition is not met
              }
            })}
        </FilterGroup>
        <SeeMore
          onClick={() => getAllOptions(!allBrands, brandRef, toggleAllBrands)}
        >
          {!allBrands ? "See all" : "See less"}
        </SeeMore>
      </PanelBody>
      {/* temp not used */}
      {/* <PanelBody>
        <PanelTitle>
          <span>
            Categorieën <small>({cats.length})</small>
          </span>
        </PanelTitle>
        <FilterGroup ref={catsRef}>
          {cats.map((row, index) => {
            if (index < 6) {
              return (
                <FilterItem key={index} title={row}>
                  <input
                    type="checkbox"
                    name={row}
                    id={row}
                    defaultValue={row}
                    onClick={(e) => {
                      isCheckedCat(e);
                    }}
                  />
                  <label htmlFor={row}>{capitalise(row)}</label>
                </FilterItem>
              );
            } else if (allCats) {
              return (
                <FilterItem key={index} title={row}>
                  <input
                    type="checkbox"
                    name={row}
                    id={row}
                    defaultValue={row}
                    onClick={(e) => {
                      isCheckedCat(e);
                    }}
                  />
                  <label htmlFor={row}>{row}</label>
                </FilterItem>
              );
            } else {
              return false;
            }
          })}
        </FilterGroup>
        <SeeMore
          onClick={() => getAllOptions(!allCats, catsRef, toggleAllCats)}
        >
          {!allCats ? "See all" : "See less"}
        </SeeMore>
      </PanelBody> */}
    </StyledFilterPanel>
  );
};

export { FilterPanel };
