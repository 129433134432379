import React from "react";

import { InputField } from "./InputField";
import {
  LoginWrapper,
  LoginOutBox,
  Title,
  ValidateForm,
  BoxBody,
  BoxHeader,

} from "../components/LoginBox";
import { Container } from "./Container";
import { InvertSecondaryButton } from "./SecondaryButton";

import { svgs } from "./Tokens";
import parse from "html-react-parser";

export const ChangePassword = ({
  onHandleFormChangeChangePswd,
  onHandleFormChangeConfirm,
  form,
  onPasswordConfirm,
  onPasswordChange,
}) => {
  const [active, toggleBox] = React.useState(false);

  return (
    <LoginWrapper>
      <Container align="center">
        <LoginOutBox data-active={!active} color="pink">
          <BoxHeader onClick={() => {
            if (active) {
              toggleBox(active);
            }
          }}>
            <Title
              style={{ whiteSpace: "nowrap" }}>Wijzig {!!active ? parse(svgs.chevronDown) : ""}
            </Title>
            <span>Maak hier een nieuw wachtwoord aan.</span>
          </BoxHeader>
          <BoxBody>
            <ValidateForm>
              <InputField
                color="white"
                icon="user"
                value={form.change.username}
                onChange={onHandleFormChangeChangePswd}
                name="username"
                placeholder={"Username"}
              />
              <InputField
                color="white"
                icon="key"
                type="password"
                onChange={onHandleFormChangeChangePswd}
                value={form.change.password}
                name="password"
                placeholder={"New password"}
              />
              <InputField
                color="white"
                icon="key"
                type="password"
                onChange={onHandleFormChangeChangePswd}
                value={form.change.newPassword}
                name="newPassword"
                placeholder={"New password"}
              />
              <InvertSecondaryButton
                color="pink"
                type="button"
                name="button"
                onClick={onPasswordChange}
              >
                Stuur code
              </InvertSecondaryButton>
            </ValidateForm>
          </BoxBody>
        </LoginOutBox>
        <LoginOutBox data-active={active} color="purple">
          <BoxHeader onClick={() => {
            if (!active) {
              toggleBox(!active);
            }
          }}>
            <Title>
              Activeer {!active ? parse(svgs.chevronDown) : ""}
            </Title>
            <span>Controleer uw e-mail voor de verificatiecode.</span>
          </BoxHeader>
          <BoxBody>
            <ValidateForm>
              <InputField
                color="white"
                icon="lock"
                type="text"
                value={form.confirm.verification}
                onChange={onHandleFormChangeConfirm}
                name="verification"
                placeholder={"Verificatiecode"}
              />
              <InputField
                color="white"
                icon="user"
                value={form.confirm.username}
                onChange={onHandleFormChangeConfirm}
                name="username"

                placeholder={"Username"}
              />
              <InputField
                color="white"
                icon="key"
                type="password"
                value={form.confirm.newPassword}
                onChange={onHandleFormChangeConfirm}
                name="newPassword"

                placeholder={"New password"}
              />

              <InvertSecondaryButton
                color="purple"
                type="button"
                name="button"
                onClick={onPasswordConfirm}

              >
                Activeer
              </InvertSecondaryButton>
            </ValidateForm>
          </BoxBody>
        </LoginOutBox>


      </Container>
    </LoginWrapper >
  );
};

export default ChangePassword;
