const hasLowerCase = (value) => {
  let lowerCaseLetters = /[a-z]/g;
  return value.match(lowerCaseLetters);
};

const hasUpperCase = (value) => {
  let upperCaseLetters = /[A-Z]/g;
  return value.match(upperCaseLetters);
};

const hasNumber = (value) => {
  var numbers = /[0-9]/g;
  return value.match(numbers);
};

const isMinimumLength = (value) => {
  return value.length >= 8;
};

export const validatePassword = (password) => {
  return {
    lowerCaseValid: hasLowerCase(password),
    upperCaseValid: hasUpperCase(password),
    numberValid: hasNumber(password),
    lengthValid: isMinimumLength(password),
  };
};
