import React from "react";

import styled from "styled-components";

import { Container } from "./Container";

import { InvertSecondaryButton } from "./SecondaryButton";
import { InputField } from "./InputField";
import { CheckBox } from "./CheckBox";

import { radius, fontSizes, spacing, colors, svgs } from "./Tokens";
import parse from "html-react-parser";

export const LoginInputWrapper = styled.div`
  display: block;
  width: 100%;
  background: #f5f5f5;
  height: 100%;
  border-radius: 2.5rem;
  padding: 0 2rem;
  text-align: center;
  box-shadow: inset 0 0 0.3rem;
  position: relative;
  margin-bottom: 1rem;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  height: 5rem;
  margin-bottom: 1rem;
`;

export const LoginInputForm = styled.input`
  display: block;
  width: 100%;
  background: #f5f5f5;
  height: 100%;
  border-radius: 2.5rem;
  padding: 0 2rem;
  text-align: center;
  box-shadow: inset 0 0 0.3rem;
  box-sizing: border-box;
  outline: none;
`;

export const LoginInputSpan = styled.span`
  content: "";
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  border-radius: 3.5rem;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(0, 178, 111, 0.5);
  height: 5rem;
`;

export const LoginWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const ValidateForm = styled.div`
  width: 100%;
`;
export const Title = styled.h3`
  font-size: ${fontSizes.big};
  margin: 0;
`;

export const LoginOutBox = styled.div`
  background-color: #fff;
  width: 60rem;
  max-width: 100%;
  border-radius: ${radius.small};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;

  @media screen and (min-width: 768px) {
    width: 40%;
  }

  @media screen and (max-width: 767px) {
    &[data-active="false"] {
      border-top: 2px dashed currentColor;
      border-bottom: 2px dashed currentColor;
      > div {
        &:first-child {
          cursor: pointer;
          span {
            display: none;
          }
          svg {
            display: block;
          }
        }
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }

  &:first-of-type:not(:last-of-type) {
    border-top: 0;
    border-radius: ${radius.small} ${radius.small} 0 0;
    @media screen and (min-width: 768px) {
      border-radius: ${radius.small} 0 0 ${radius.small};
    }
  }

  &:last-of-type:not(:first-of-type) {
    border-bottom: 0;
    @media screen and (min-width: 768px) {
      border-radius: 0 ${radius.small} ${radius.small} 0;
    }
    border-radius: 0 0 ${radius.small} ${radius.small};

    &::after {
      content: "";
      height: calc(100% - ${spacing.large} - ${spacing.large});
      width: 1px;
      background-color: ${colors.grey};
      top: ${spacing.large};
      left: 0;
      position: absolute;
    }
  }

  color: ${({ color }) => {
    if (color === "purple" || !color) {
      return colors.purple;
    } else {
      return colors.pink;
    }
  }};
`;

export const BoxHeader = styled.div`
  padding: ${spacing.large};
  width: 100%;

  svg {
    width: 4rem;
    float: right;
    display: none;
  }
`;

export const BoxBody = styled.div`
  padding: 0 ${spacing.large};
  width: 100%;

  > div {
    height: 100%;
  }
`;

export const BoxFooter = styled.div`
  padding: 0 ${spacing.large} ${spacing.large};
  align-self: flex-end;
`;

export const LoginBox = ({
  onHandleFormChangeLogin,
  onHandleFormChangeSignUp,
  form,
  onLogin,
  onSignUp,
}) => {
  const [active, toggleBox] = React.useState(false);
  return (
    <LoginWrapper>
      <Container align="center">
        <LoginOutBox data-active={active} color="pink">
          <BoxHeader
            onClick={() => {
              if (!active) {
                toggleBox(!active);
              }
            }}
          >
            <Title>
              Nieuw account {!active ? parse(svgs.chevronDown) : ""}
            </Title>
            <span>Maak hier een nieuw account aan.</span>
          </BoxHeader>
          <BoxBody>
            <ValidateForm>
              <InputField
                color="white"
                icon="mail"
                onChange={onHandleFormChangeSignUp}
                value={form.signUp.email}
                name="email"
                placeholder={"E-mail"}
                data-testid="@create/email_input"
              />
              <InputField
                color="white"
                icon="user"
                onChange={onHandleFormChangeSignUp}
                value={form.signUp.username}
                name="username"
                placeholder={"Gebruikersnaam"}
                data-testid="@create/username_input"
              />
              <InputField
                color="white"
                icon="key"
                type="password"
                onChange={onHandleFormChangeSignUp}
                value={form.signUp.password}
                name="password"
                placeholder={"Wachtwoord"}
                data-testid="@create/password_input"
              />
              <InputField
                color="white"
                icon="phone"
                type="text"
                onChange={onHandleFormChangeSignUp}
                value={form.signUp.phone}
                name="phone"
                placeholder={"Telefoonnummer"}
              />
            </ValidateForm>
          </BoxBody>
          <BoxFooter>
            <InvertSecondaryButton
              color="pink"
              onClick={onSignUp}
              data-testid="@create/submit_button"
            >
              Registreer
            </InvertSecondaryButton>
          </BoxFooter>
        </LoginOutBox>

        <LoginOutBox data-active={!active} color="purple">
          <BoxHeader
            onClick={() => {
              if (active) {
                toggleBox(!active);
              }
            }}
          >
            <Title>Inloggen {!!active ? parse(svgs.chevronDown) : ""}</Title>
            <span>Log in met uw bestaande account.</span>
          </BoxHeader>
          <BoxBody>
            <ValidateForm>
              <InputField
                color="white"
                icon="user"
                onChange={onHandleFormChangeLogin}
                value={form.login.username}
                name="username"
                placeholder={"Gebruikersnaam"}
                data-testid="@login/username_input"
              />
              <InputField
                color="white"
                icon="key"
                type="password"
                onChange={onHandleFormChangeLogin}
                value={form.login.password}
                name="password"
                placeholder={"Wachtwoord"}
                data-testid="@login/password_input"
              />
              <InputField
                color="white"
                icon="lock"
                type="text"
                onChange={onHandleFormChangeLogin}
                value={form.login.verification}
                name="verification"
                placeholder={"Verificatiecode"}
              />
              <CheckBox
                text="small"
                label="Bewaar mijn gegevens voor de volgende keer"
                id="terms"
              />
            </ValidateForm>
            <span
              style={{ cursor: "pointer" }}
              color="purple"
              onClick={() => window.location.replace("/change_password")}
            >
              Wachtwoord vergeten?
            </span>
          </BoxBody>
          <BoxFooter>
            <InvertSecondaryButton
              color="purple"
              onClick={onLogin}
              data-testid="@login/submit_button"
            >
              Log in
            </InvertSecondaryButton>
          </BoxFooter>
        </LoginOutBox>
      </Container>
    </LoginWrapper>
  );
};

export default LoginBox;
