import styled from "styled-components";
import { colors } from "./Tokens";
import { PrimaryButton } from "./PrimaryButton";

export const SecondaryButton = styled(PrimaryButton)`
  background-color: transparent;
  border: 0.3rem solid #fff;
  ${({ multiple }) => {
    if (multiple) {
      return `
        margin: 1.6rem;
        min-width: 40%;
      `;
    }
  }};
  margin: ${({ margin }) => {
    if (margin) {
      return `0 1.6rem`;
    }
  }};
  ${(props) => {
    if (props.color === "pink" || props.color === "purple") {
      return `
        border-color: ${colors.white};
        color: ${colors.white};
      `;
    } else {
      return `
        border-color: ${colors.pink};
        color: ${colors.pink};
      `;
    }
  }}
  &:hover {
    background-color: #fff;

    ${(props) => {
      if (props.color === "purple" || !props.color) {
        return `
          color: ${colors.purple};
          border-color: ${colors.purple};
        `;
      } else if (props.color === "pink") {
        return `
          color: ${colors.pink};
          border-color: ${colors.pink};
        `;
      }
    }}
  }
`;

export const InvertSecondaryButton = styled(SecondaryButton)`
  border-color: ${({ color }) => colors[color]};
  color: ${({ color }) => colors[color]};
  cursor: pointer;
  &:hover {
    background-color: ${({ color }) => colors[color]};
    border-color: ${colors.white};
    color: ${colors.white};
  }
`;

export const InvertSecondaryButtonPagination = styled(SecondaryButton)`
  border-color: ${({ color }) => colors[color]};
  color: ${({ color }) => colors[color]};

  &:hover {
    background-color: ${colors.pink};
    border-color: ${colors.purple};
    color: ${colors.purple};
  }
`;
