import styled from "styled-components";
import { colors, radius } from "./Tokens";

export const PrimaryButton = styled.button<{
  size?: string;
}>`
  height: 8rem;
  display: inline-flex;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: ${radius.small};
  border: none;
  justify-content: center;
  padding: 0 4rem;
  margin: 1.6rem 0;
  ${(props) => {
    if (props.color === "pink" || props.color === "purple") {
      return `
        background-color: ${colors.pink};
        color: ${colors.white};
      `;
    } else {
      return `
        background-color: ${colors.pink};
        color: ${colors.white};
      `;
    }
  }}
  &:hover {
    ${(props) => {
      if (props.color === "purple" || !props.color) {
        return `
          background-color: ${colors.purple};
          color: ${colors.white};
        `;
      } else if (props.color === "pink") {
        return `
          background-color: ${colors.white};
          color: ${colors.pink};
        `;
      }
    }}
  }

  &[disabled] {
    pointer-events: none;
    user-select: none;
    opacity: 0.3;
  }

  ${({ size }) => {
    if (size === "small") {
      return `
        height: 6rem;
      `;
    }
  }};
`;

