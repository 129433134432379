import * as api from "../sdk";

class Client {
  public readonly orders: api.OrderApi;
  public readonly products: api.ProductApi;
  public readonly portalProducts: api.PortalProductApi;
  public readonly questions: api.QuestionApi;
  public readonly checkout: api.CheckoutApi;
  public readonly cart: api.CartApi;

  constructor({ basePath = window.location.origin } = {}) {
    this.orders = new api.OrderApi({ basePath });
    this.products = new api.ProductApi({ basePath });
    this.portalProducts = new api.PortalProductApi({ basePath });
    this.questions = new api.QuestionApi({ basePath });
    this.checkout = new api.CheckoutApi({ basePath });
    this.cart = new api.CartApi({ basePath });
  }
}

export const apiClient = new Client({ basePath: "/api" });
