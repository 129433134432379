import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { colors } from "../components/Tokens";
import { createGlobalStyle } from "styled-components";

import { ChangePassword } from "../components/ChangePassword";
import { Wrapper } from "../components/Wrapper";
import { withAuthenticationContext } from "../context/AuthenticationContext";

const GlobalStyle = createGlobalStyle`
.Toastify__progress-bar {
  background-color:${colors.pink};
}
.Toastify__toast--success {
  color:${colors.purple};
}
.Toastify__toast-icon svg {
  fill:${colors.pink};
}
  #root{
    height: 100%;
  }
  html{
    font-size: 6px;

    @media screen and (min-width:480px){
      font-size: 8px;
    }
  }
`;

class ChangePasswordScreenComponent extends React.Component {
  state = {
    change: {
      username: "",
      password: "",
      newPassword: "",
    },
    confirm: {
      verification: "",
      username: "",
      newPassword: "",
    },
  };

  handleValidation(username, password) {
    return username && password;
  }

  handleNewValidation(username, newPassword) {
    return username && newPassword;
  }

  onHandleFormChangeChangePswd = ({ target }) => {
    const { change } = this.state;
    this.setState({
      change: {
        ...change,
        [target.name]: target.value,
      },
    });
  };
  onHandleFormChangeConfirm = ({ target }) => {
    const { confirm } = this.state;
    this.setState({
      confirm: {
        ...confirm,
        [target.name]: target.value,
      },
    });
  };

  onPasswordChange = async (e) => {
    const { authenticationService } = this.props;
    const { username } = this.state.change;

    const { password, newPassword } = this.state.change;
    if (this.handleValidation(username, password) && newPassword === password) {
      await authenticationService.resetPassword(username.toLowerCase());
      toast.success("Verification code sent to e-mail.");
    } else {
      toast.error(e.message);
    }
  };

  onPasswordConfirm = async (e) => {
    const { authenticationService } = this.props;

    const { username, verification, newPassword } = this.state.confirm;

    if (this.handleNewValidation(username, newPassword)) {
      try {
        await authenticationService.confirmPassword(
          username,
          verification,
          newPassword.trim()
        );
        toast.success("Password changed");
        window.location.replace("/login");
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  render() {
    return (
      <Wrapper color="purple" className="login-box">
        <GlobalStyle />
        <ToastContainer />
        <ChangePassword
          onHandleFormChangeChangePswd={this.onHandleFormChangeChangePswd}
          onHandleFormChangeConfirm={this.onHandleFormChangeConfirm}
          onPasswordChange={this.onPasswordChange}
          onPasswordConfirm={this.onPasswordConfirm}
          form={this.state}
        />
      </Wrapper>
    );
  }
}
export const ChangePasswordScreen = withAuthenticationContext(
  ChangePasswordScreenComponent
);
export default ChangePasswordScreen;
