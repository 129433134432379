import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";
import { Wrapper } from "../components/Wrapper";
import LoginBox from "../components/LoginBox";
import logo from "../assets/msm_logo_wit.png";

import { useNavigate } from "react-router-dom";
import { useAuthenticationContext } from "../context/AuthenticationContext";

const GlobalStyle = createGlobalStyle`
  #root {
    height: 100%;
  }
  html {
    font-size: 6px;
    @media screen and (min-width: 480px) {
      font-size: 8px;
    }
  }
`;

export function PortalLoginScreen() {
  const [login, setLogin] = useState({
    username: "",
    password: "",
    verification: "",
  });
  const [signUp, setSignUp] = useState({
    username: "",
    password: "",
    email: "",
    phone: "",
    validationErrors: {},
  });

  const navigate = useNavigate();
  const authenticationService = useAuthenticationContext();

  function handleValidation(username, password) {
    return username && password;
  }

  async function onLogin(e) {
    e.preventDefault();
    const { username, password, verification } = login;

    if (handleValidation(username, password)) {
      try {
        await authenticationService.login(
          username.toLowerCase(),
          password,
          verification
        );
        navigate("/");
      } catch (e) {
        toast.error(e.message);
        if (e.type === "RESET_PASSWORD_REQUIRED") {
          navigate("/change_password", { username: e.payload.username });
        }
      }
    }
  }

  async function onSignUp(e) {
    e.preventDefault();
    const { username, password, email } = signUp;

    if (handleValidation(username, password)) {
      try {
        await authenticationService.signUp(
          username.toLowerCase().trim(),
          password.trim(),
          email.trim()
        );
        navigate("/");
      } catch (e) {
        toast.error(e.message);
      }
    }
  }

  function handleFormChangeLogin(e) {
    const { name, value } = e.target;
    setLogin((prevLogin) => ({
      ...prevLogin,
      [name]: value,
    }));
  }

  function handleFormChangeSignUp(e) {
    const { name, value } = e.target;
    setSignUp((prevSignUp) => ({
      ...prevSignUp,
      [name]: value,
    }));
  }

  return (
    <Wrapper color="pink" className="login-box">
      <div
        className="logo"
        style={{
          alignItems: "center",
          display: "flex",
          position: "absolute",
          flexDirection: "column",
          justifyContent: "space-around",
          marginTop: "10px",
          height: "700px",
          top: "6rem",
        }}
      >
        <img src={logo} alt="Logo" />

        <GlobalStyle />
        <ToastContainer />

        <LoginBox
          onHandleFormChangeLogin={handleFormChangeLogin}
          onHandleFormChangeSignUp={handleFormChangeSignUp}
          onLogin={onLogin}
          onSignUp={onSignUp}
          form={{ login, signUp }}
        />
      </div>
    </Wrapper>
  );
}

export default PortalLoginScreen;
