import React from "react";
import { Routes, Route } from "react-router-dom";
import { SignUpScreen } from "./views/SignUpScreen";
import { PortalLoginScreen } from "./views/PortalLoginScreen";
import { ChangePasswordScreen } from "./views/ChangePasswordScreen";
import { CMS } from "./components/portal/pages/CMS";
import Landing from "./components/portal/pages/Landing";
import { GlobalStyles } from "./components/portal/GlobalStyles";

import Default from "./components/Default";

const App = () => {
  return (
    <React.Fragment>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/sign_up" element={<SignUpScreen />} />
        <Route exact path="/admin_panel" render={() => <CMS />} />
        <Route exact path="/login" element={<PortalLoginScreen />} />
        <Route
          exact
          path="/change_password"
          element={<ChangePasswordScreen />}
        />
        <Route element={<Default />} />
      </Routes>
    </React.Fragment>
  );
};

export default App;
