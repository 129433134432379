import * as AWS from "aws-sdk/global";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AWS_REGION,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_CLIENT_ID,
} from "../config/aws";

const errorResponse = (
  type: string,
  message: string,
  payload: { username: string } | null = null
) => ({
  type,
  message,
  payload,
});

export class AuthenticationService {
  readonly userPool: AmazonCognitoIdentity.CognitoUserPool;
  readonly region: string;
  private session: AmazonCognitoIdentity.CognitoUserSession | null = null;

  constructor() {
    this.region = AWS_REGION as string;
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: AWS_USER_POOL_ID as string, // Your user pool id here
      ClientId: AWS_USER_POOL_CLIENT_ID as string, // Your client id here
    });
  }

  async resetPassword(username: string) {
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        console.log("call result: " + result);
      },
      onFailure: function (err) {
        alert(err);
      },
    });
  }

  async confirmPassword(
    username: string,
    verification: string,
    newPassword: string
  ) {
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmPassword(verification, newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve();
        },
      });
    });
  }

  async login(username: string, password: string, verification?: string) {
    return await new Promise((resolve, reject) => {
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails({
          Username: username,
          Password: password,
        });

      var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: username,
        Pool: this.userPool,
      });

      if (verification) {
        cognitoUser.confirmRegistration(verification, true, (err, result) => {
          if (err) {
            return reject(errorResponse("FAIL", err.message, err));
          }
          return resolve(result);
        });
      }

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          var accessToken = result.getAccessToken().getJwtToken();
          AWS.config.region = this.region;

          resolve(accessToken);
          localStorage.setItem("accessToken", accessToken);
        },

        onFailure: (err) => {
          reject(errorResponse("FAIL", err.message, err));
        },

        newPasswordRequired: () => {
          cognitoUser.getSession(
            (
              err: Error,
              session: AmazonCognitoIdentity.CognitoUserSession | null
            ) => {
              this.session = session;
              reject(
                errorResponse(
                  "RESET_PASSWORD_REQUIRED",
                  "Requires a password reset",
                  { username }
                )
              );
            }
          );
        },
      });
    });
  }

  async signUp(
    username: string,
    password: string,
    email: string,
    phone: string
  ) {
    return await new Promise((resolve, reject) => {
      var attributeList = [];

      var dataEmail = { Name: "email", Value: email };
      var dataPhoneNumber = { Name: "phone_number", Value: phone };

      var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
        dataEmail
      );
      var attributePhoneNumber = new AmazonCognitoIdentity.CognitoUserAttribute(
        dataPhoneNumber
      );

      attributeList.push(attributeEmail);
      attributeList.push(attributePhoneNumber);

      this.userPool.signUp(
        username,
        password,
        attributeList,
        [],
        function (err, result) {
          if (err) {
            toast.error(err.message || JSON.stringify(err));
            return;
          }

          var cognitoUser = result?.user;
          toast.success("Verification code sent to e-mail.");
          console.log("user name is " + cognitoUser?.getUsername());
        }
      );
    });
  }
}
export default AuthenticationService;
