import styled from "styled-components";

import { colors, fontSizes } from "./Tokens";
import { Container } from "./Container";
import { IconCircle } from "./IconCircle";
import logo from "../assets/msm_logo_wit.png";

const StyledHeader = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  position: fixed;
  width: 100%;
  background-color: ${colors.pink};
  top: 0;
  z-index: 2;
  color: white;
  .header__col {
    margin: 0;
    .welcome-msg {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    @media screen and (min-width: 768px) {
      margin: 0 2.4rem;
    }

    &:first-child {
      margin-left: 0;
      flex: 1;

      @media screen and (min-width: 768px) {
        flex: unset;
      }
    }
    &:last-child {
      margin-right: 0;
    }

    &.col__flex {
      display: flex;
      align-items: center;

      span {
        display: block;
        color: ${colors.pink};
        font-size: ${fontSizes.xsmall};
      }
    }
  }

  .logo {
    max-height: 42px;
    max-width: 100%;
  }
`;

const Header = ({ ...props }) => {
  return (
    <StyledHeader>
      <Container valign="center" align="space-between">
        <div className="header__col">
          <img alt="logo" src={logo} className="logo" />
        </div>
        <div className="header__col header__search"></div>

        <div className="header__col col__flex">
          <div style={{ position: "relative" }}>
            <IconCircle
              color="white"
              style={{ fontWeight: "700" }}
            ></IconCircle>
          </div>
        </div>
      </Container>
    </StyledHeader>
  );
};

export { Header };
