import React from "react";
import styled from "styled-components";

import { colors } from "./Tokens";

interface ProgressBarProps {
  prodViewed: number;
  prodTotal: number;
}

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${colors.grey};
  border-radius: 50px;
  overflow: hidden;
  margin-left: 10rem;
  margin-right: 10rem;
`;

const ProgressBarFill = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => percentage}%;
  height: 100%;
  background-color: ${colors.purple};
  transition: width 0.5s ease-in-out;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ prodViewed, prodTotal }) => {
  const percentage = (prodViewed / prodTotal) * 100;

  return (
    <ProgressBarContainer>
      <ProgressBarFill percentage={percentage} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
