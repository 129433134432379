import styled from "styled-components";
import { radius, fontSizes, spacing, colors } from "./Tokens";

const StyledCheckBox = styled.div`
  padding: 0;
  border-color: white;
  font-size: 2.4rem;
  position:relative;
  z-index:1;
  display: flex;
  margin: ${spacing.small} 0;
  align-items: center;

  input, .custom-checkbox{
    height: 30px;
    width: 30px;
    min-width: 30px;
    display: inline-flex;
    vertical-align: middle;
  }
  input{
    opacity: 0;
  }
  .custom-checkbox{
    background-color: transparent;
    color: ${colors.pink};
    border: 2px solid ${colors.pink};
    position: absolute;
    left:0;
    pointer-events: none;
    border-radius: ${radius.small};
    transition: border-color .3s, color .3s;

    svg{
      opacity: 0;
      transition: opacity .3s;
      margin-top: -2px;
      z-index: -1;
      height: 30px;
      width: 30px;
    }
  }

  input:checked{
    + .custom-checkbox{
      color: ${colors.purple};
      border-color: ${colors.purple};

      svg{
        opacity: 1;
      }
    }
  }

  label{
    vertical-align:middle;
    font-size: ${({ text }) => text === 'small' ? fontSizes.xsmall : fontSizes.normal};
    margin-left: ${spacing.xsmall};
  }
`;

export const CheckBox = ({ label, id, ...props }) => {

  return (
    <StyledCheckBox {...props}>
      <input id={id} type='checkbox' />
      <span className="custom-checkbox"><svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"></path></svg></span>
      {label.length > 0 ? <label htmlFor={id}>{label}</label> : ''}
    </StyledCheckBox>
  );
}
