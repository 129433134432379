import React from "react";
import styled from "styled-components";

import { SignUp, PASSWORD_FIELD } from "../components/SignUp";
import { withAuthenticationContext } from "../context/AuthenticationContext";
import { validatePassword } from "../utils/validator";

import star from "../assets/sterretje.svg";

export const SignUpScreenStyle = styled.div`
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;

class SignUpScreenComponent extends React.Component {
  state = {
    username: "",
    password: "",
    email: "",
    phone: "",
    validationErrors: {},
  };

  handleValidation(username, password) {
    return username && password;
  }

  validateInput = (name, value) => {
    if (name === PASSWORD_FIELD) {
      return validatePassword(value);
    }
    return {};
  };

  onHandleFormChange = ({ target }) => {
    const { validationErrors } = this.state;
    const { name, value } = target;

    this.setState({
      [name]: value,
      validationErrors: {
        ...validationErrors,
        [name]: this.validateInput(name, value),
      },
    });
  };

  onSignUp = async (e) => {
    const { authenticationService } = this.props;
    const { username, password, email } = this.state;

    if (this.handleValidation(username, password)) {
      try {
        await authenticationService.signUp(
          username.toLowerCase().trim(),
          password.trim(),
          email.trim()
        );

        window.location.replace("/configurator/question/overview");
      } catch (e) {}
    }
  };

  render() {
    const { validationErrors } = this.state;
    return (
      <SignUpScreenStyle>
        <img
          src={star}
          style={{
            opacity: "0.7",
            position: "fixed",
            width: "100%",
            top: "-100px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          alt="star"
        />
        <SignUp
          onFormChange={this.onHandleFormChange}
          onSignUp={this.onSignUp}
          form={this.state}
          validationErrors={validationErrors}
        />
      </SignUpScreenStyle>
    );
  }
}
export const SignUpScreen = withAuthenticationContext(SignUpScreenComponent);
export default SignUpScreen;
