import React from "react";
import { Header } from "../../CMS_Header";
import { UploadFile } from "../../CMS_UploadFile";

export class CMS extends React.Component {
  render() {
    return (
      <>
        <Header />
        <UploadFile />
      </>
    );
  }
}

export default CMS;
