import styled from "styled-components";
import { colors, radius, spacing, svgs } from "./Tokens";
import parse from "html-react-parser";

const StyledInputWrap = styled.div`
  position: relative;
  margin: ${spacing.small} 0;

  &.icon > input {
    padding-left: 7rem;
  }

  &.icon > div {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 7rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    pointer-events: none;
  }

  svg {
    max-height: 2.4rem;
    max-width: 2.7rem;
    width: 100%;
  }
`;

export const StyledInputField = styled.input`
  background-color: #fff;
  width: 100%;
  font-size: 2.4rem;
  color: ${colors.purple};
  border-style: solid;
  border-width: 0.2rem;
  border-radius: ${radius.small};
  border-color: #fff;
  box-sizing: border-box;
  outline: none;
  height: 6rem;
  padding: 1.6rem;
  font-weight: 400;
  width: ${({ size }) => size + "rem"};

  + div svg path {
    fill: ${colors.purple};
  }

  ${(props) => {
    if (props.color === "white") {
      return `
        background-color: ${colors["grey-light"]};
        border-color: ${colors["grey-light"]};
      `;
    }
  }}

  ${(props) => {
    if ((props as any).appearance === "none") {
      return `
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `;
    }
  }}

  &::placeholder {
    color: ${colors["grey-dark"]};
  }

  &:placeholder-shown {
    + div svg path {
      fill: ${colors["grey"]};
    }
  }
  &:-ms-input-placeholder {
    + div svg path {
      fill: ${colors["grey"]};
    }
  }

  &:active,
  &:focus {
    border-color: ${colors.pink};
    color: ${colors.pink};

    + div svg path {
      fill: ${colors.pink};
    }
  }
`;

export const InputField = ({
  icon,
  ...props
}: React.ComponentProps<typeof StyledInputField> & {
  icon?: keyof typeof svgs;
}) => {
  return (
    <StyledInputWrap className={!!icon ? "icon" : ""}>
      <StyledInputField {...props}></StyledInputField>
      {!!icon ? <div>{parse(svgs[icon])}</div> : ""}
    </StyledInputWrap>
  );
};
