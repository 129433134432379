import React, { useState } from "react";

import { FileUploader } from "react-drag-drop-files";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PrimaryButton } from "./PrimaryButton";
import star from "../assets/sterretje.svg";
import { colors } from "./Tokens";

import { apiClient } from "../utils/apiClient";

import styled from "styled-components";

const Container = styled.div`
  background-color: ${colors.pink};
  background-image: url(${star});
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledUpload = styled.div`
  background-color: #fff;
  background-position: center;
  color: ${colors.purple};
  font-family: Montserrat;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35vw;
  height: 50%;

  label {
    border-color: #aa0054;
  }

  svg {
    margin-right: 10px;
    filter: invert(9%) sepia(100%) saturate(7370%) hue-rotate(324deg)
      brightness(68%) contrast(101%);
  }
`;

const UploadFile = () => {
  const fileTypes = ["CSV"];
  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };

  const updateDatabase = async (file) => {
    const formData = new FormData();
    formData.append("CSV", file, file.body);
  
    const postres = await apiClient.portalProducts.postFile(formData);
    console.log(postres);
  };
  const [startDate, setStartDate] = useState(new Date());

  return (
    <Container>
      <StyledUpload>
        <h1 style={{ marginBottom: "100px" }}>CMS</h1>

        <FileUploader
          multiple={false}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
        />
        <p>
          {file ? `Bestandsnaam: ${file.name}` : "Nog geen bestand geüpload"}
        </p>
        <DatePicker
          disabled
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => setStartDate(date)}
        />
        <PrimaryButton
          onClick={() => {
            updateDatabase(file);
          }}
        >
          Update
        </PrimaryButton>
      </StyledUpload>
    </Container>
  );
};
export { UploadFile };
