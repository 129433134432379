import styled from "styled-components";

import { colors, spacing } from "./Tokens";

export const IconCircle = styled.div`
  display: flex;
  color: ${({ color }) => colors[color]};
  align-items: center;
  width: 4.8rem;
  min-width: 4.8rem;
  height: 4.8rem;
  min-height: 4.8rem;
  margin-right: ${spacing.small};
  border: 1px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;

  svg {
    max-width: 50%;
    min-width: 35%;
    max-height: 50%;
    min-height: 35%;
  }
`;
