import styled from "styled-components";
import { radius, spacing } from "./Tokens";

import { SecondaryButton } from "./SecondaryButton";
import parse from "html-react-parser";

const StyledIconButton = styled(SecondaryButton)`
  border-radius: ${radius.big};
  padding: 0 ${spacing.normal};
  font-size: 2.4rem;
  ${({ size }) => {
    if (size === "small") {
      return `
        padding: 0;
        margin: 0 ${spacing.xsmall} 0 0;
        color: inherit;
        border-color: inherit;
      `;
    }
  }};
  min-width: ${({ size }) => {
    if (size === "small") {
      return "4rem";
    } else {
      return "8rem";
    }
  }};
  height: ${({ size }) => {
    if (size === "small") {
      return "4rem";
    } else {
      return "8rem";
    }
  }};

  svg {
    height: 50%;
    width: auto;

    + span {
      display: none;

      @media screen and (min-width: 479px) {
        margin-left: 1.2rem;
        display: inline;
      }
    }
  }
`;

export const IconButton = ({
  type,
  children,
  ...props
}: React.ComponentProps<typeof StyledIconButton>) => {
  let svg = "";
  if (type === "contact") {
    svg =
      "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z' ></path></svg>";
  } else if (type === "close") {
    svg =
      "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'><path fill='currentColor' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>";
  }
  return (
    <StyledIconButton {...props}>
      {parse(svg)}
      {!!children ? <span>{children}</span> : ""}
    </StyledIconButton>
  );
};
