import Select from "react-select";

const customStyle = {
  container: (styles) => {
    return {
      ...styles,
      display: "inline-block",
    }
  },
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: "#979797",
      height: "4rem",
      padding: "0 .6rem",
      color: "#fff",
      borderRadius: ".8rem 0 0 .8rem",
      border: "0",
      fontSize: "1.6rem",
      cursor: "pointer",

      ":hover": {
        border: "0",
        boxShadow: "none",
      }
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      border: "2px solid #979797",
      borderTopWidth: "8px",
      margin: "-8px 0 0",
      borderRadius: "0 0 .8rem .8rem",
      boxShadow: "none",
      overflow: "hidden",
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      padding: 0,


    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      width: "100%",
      cursor: isDisabled ? "not-allowed" : "pointer",
      fontSize: "1.6rem",
      backgroundColor: isSelected ? "#eee" : "transparent",
      color: "#979797",

      ":hover": {
        backgroundColor: "#eee",
      },

      ":active": {
        ...styles[":active"],
        color: "#fff",
        backgroundColor: !isDisabled && (isSelected ? "#979797" : "#979797"),
      },
    };
  },
  input: (styles) => {
    return {
      ...styles,

      "input": {
        width: "0 !important",
        height: "0 !important"
      }
    }
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      height: "100%",
      paddingRight: 0
    }
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: "#ddd",
      position: "static",
      transform: "none",
    }
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      position: "static",
      transform: "none",
      maxWidth: "100%",
      color: "#fff",
    }
  },
  indicatorSeparator: (styles) => {
    return {
      ...styles,
      display: "none"
    }
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: "#fff",

      ":hover": {
        color: "#fff"
      }
    }
  }
};

const secondaryStyle = {
  container: (styles) => {
    return {
      ...styles,
      display: "inline-block",
      fontWeight: 400,
      minWidth: "100px",
    }
  },
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: "#eee",
      height: "4rem",
      padding: "0 .6rem",
      color: "#fff",
      borderRadius: ".8rem",
      border: "2px solid #979797",
      fontSize: "1.6rem",
      cursor: "pointer",
      ":hover": {
        border: "2px solid #979797",
        boxShadow: "none"
      }
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      border: "2px solid #979797",
      borderTopWidth: "0",
      margin: "-8px 0 0",
      borderRadius: "0 0 .8rem .8rem",
      boxShadow: "none",
      overflow: "hidden"
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      padding: "8px 0 0"
    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      width: "100%",
      cursor: isDisabled ? "not-allowed" : "pointer",
      fontSize: "1.6rem",
      backgroundColor: isSelected ? "#eee" : "transparent",
      color: "#979797",

      ":hover": {
        backgroundColor: "#eee",
      },

      ":active": {
        ...styles[":active"],
        color: "#fff",
        backgroundColor: !isDisabled && (isSelected ? "#979797" : "#979797"),
      },
    };
  },
  input: (styles) => {
    return {
      ...styles,

      "input": {
        width: "0 !important",
        height: "0 !important"
      }
    }
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      height: "100%",
      paddingRight: 0
    }
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: "#ddd",
      position: "static",
      transform: "none",
    }
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      position: "static",
      transform: "none",
      maxWidth: "100%",
      color: "#979797"
    }
  },
  indicatorSeparator: (styles) => {
    return {
      ...styles,
      display: "none"
    }
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: "#979797",

      ":hover": {
        color: "#979797"
      }
    }
  }
};

export const SelectField = ({ options, placeholder, ...props }) => (
  <Select options={options} {...props} defaultValue={options[0]} styles={customStyle} isSearchable={false} />
)

export const SelectFieldSecondary = ({ options, placeholder, ...props }) => (
  <Select options={options} {...props} defaultValue={options[0]} styles={secondaryStyle} isSearchable={false} />
)
