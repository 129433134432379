import styled from "styled-components";

import star from "../assets/sterretje.svg";
import { colors } from "./Tokens";

export const Wrapper = styled.div`
  background-image: url(${star});
  background-color: ${({ color }) => colors[color]};
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height:100vh;
  background-attachment:fixed;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  top: inherit;
  justify-content: center;
  align-items: center;
  color: ${colors.purple};
  
  
`;
