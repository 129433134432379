import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import styled from "styled-components";
import parse from "html-react-parser";

import { svgs, colors, fontSizes } from "./Tokens";

import { Container } from "./Container";

import { InputField } from "./InputField";
import { IconCircle } from "./IconCircle";
import logo from "../assets/msm_logo_wit.png";

const StyledHeader = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  position: fixed;
  width: 100%;
  background-color: ${colors.pink};
  top: 0;
  z-index: 2;

  .header__col {
    margin: 0;

    &:first-of-type {
      min-width: 200px;
    }

    .welcome-msg {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    @media screen and (min-width: 768px) {
      margin: 0 0rem 0 6rem;
    }

    &:first-child {
      margin-left: 0;
      flex: 1;

      @media screen and (min-width: 768px) {
        //max-width:10%
        flex: unset;
      }
    }
    &:last-child {
      margin-right: 0;
    }

    &.col__flex {
      display: flex;
      align-items: center;

      span {
        display: block;
        color: ${colors.pink};
        font-size: ${fontSizes.xsmall};
      }
    }
  }

  .logo {
    max-height: 42px;
    max-width: 100%;
  }

  .temp {
    position: absolute;
    right: 5%;
  }

  .header__search {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    order: 1;
    margin: 2rem 0 0;

    @media screen and (min-width: 768px) {
      order: initial;
      flex: 2;
      margin: 0;
    }
    > div {
      margin: 0;
      display: flex;

      input {
        height: 40px;
        font-size: 16px;
        padding: 0 1.2rem 0 3.6rem;

        + div {
          width: auto;
          margin: 0 1.2rem;

          svg {
            max-width: 1.6rem;
            padding-bottom: 6px;
          }
        }
      }

      &:last-child {
        flex: 1;
      }
    }
  }
`;

interface HeaderProps {
  searchTerm: (term: string) => void;
  products: {
    qty: number;
  }[];
  functions: {
    openCart: () => void;
    openNote: () => void;
    openHistory: () => void;
  };
}

const Header = ({ searchTerm, ...props }: HeaderProps) => {
  // const navigate = useNavigate();
  const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleLogin = () => {
    loginWithRedirect({
      authorizationParams: {
        scope: "profile email offline_access",
        prompt: "consent",
      },
    });
  };

  return (
    <StyledHeader>
      <Container v-align="center" align="space-between">
        <div className="header__col">
          <img alt="logo" src={logo} className="logo" />
        </div>
        <div className="header__col header__search">
          <InputField
            icon="search"
            color="white"
            placeholder="Zoek uw product"
            style={{ borderRadius: ".8rem" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              searchTerm(e.target.value)
            }
          />
        </div>
        {isAuthenticated && user ? (
          <div className="header__col col__flex">
            <div style={{ position: "relative" }}>
              <IconCircle color="white" style={{ fontWeight: "700" }}>
                {user.name?.charAt(0).toUpperCase()}
              </IconCircle>
            </div>
            <div className="welcome-msg" style={{ color: "white" }}>
              Welkom{" "}
              {user.name && user.name.length > 8
                ? `${user.name.substring(0, 8)}...`
                : user.name}
              <span
                style={{ cursor: "pointer", color: "white" }}
                onClick={handleLogout}
              >
                Uitloggen
              </span>
            </div>
          </div>
        ) : (
          <div onClick={handleLogin} className="header__col col__flex">
            <div style={{ position: "relative" }}>
              <IconCircle color="grey">{parse(svgs.user)}</IconCircle>
            </div>
            <div
              style={{ cursor: "pointer", color: "white" }}
              className="welcome-msg"
            >
              Log in | Registreer
              <span>Welkom!</span>
            </div>
          </div>
        )}

        <div className="header__col col__flex" style={{ position: "relative" }}>
          {!!props.products.length ? (
            <span
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                backgroundColor: colors.pink,
                color: "#fff",
                height: "20px",
                width: "20px",
                zIndex: "1",
                lineHeight: "20px",
                borderRadius: "50%",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {props.products.map((item) => item.qty).reduce((a, b) => a + b)}
            </span>
          ) : null}

          <IconCircle
            onClick={() => props.functions.openCart()}
            color="white"
            style={{ position: "relative", cursor: "pointer" }}
          >
            {parse(svgs.order)}
          </IconCircle>
        </div>
        <IconCircle
          onClick={() => props.functions.openHistory()}
          color="white"
          style={{
            position: "relative",
            cursor: "pointer",
          }}
        >
          {parse(svgs.history)}
        </IconCircle>

        <IconCircle
          onClick={() => props.functions.openNote()}
          color="white"
          style={{
            position: "relative",
            cursor: "pointer",
          }}
        >
          {parse(svgs.info)}
        </IconCircle>
      </Container>
    </StyledHeader>
  );
};

export default Header;
