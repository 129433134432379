import React, { useState } from "react";
import styled from "styled-components";
import {
  LoginWrapper,
  LoginOutBox,
  Title,
  ValidateForm,
  LoginInputWrapper,
  LoginInputForm,
  LoginInputSpan,
} from "../components/LoginBox";

import ButtonContainer from "../components/Button";

const LoginInput = ({ type, ...props }) => (
  <LoginInputWrapper>
    <LoginInputForm type={type || "text"} {...props} />
    <LoginInputSpan />
  </LoginInputWrapper>
);

const MessageBox = styled.div`
  background: #f1f1f1;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
`;

const ValidationField = styled.p`
  color: ${({ valid }) => (valid ? "green" : "red")};
`;

// Makes password field DRY: Don't Repeat Yourself
export const PASSWORD_FIELD = "password";

const PasswordMessageBox = ({ focused, validationErrors }) => {
  const { lowerCaseValid, upperCaseValid, numberValid, lengthValid } =
    validationErrors || {};

  if (!focused) {
    return null;
  }

  return (
    <MessageBox id="message">
      <h3>Password must contain the following:</h3>
      <ValidationField valid={lowerCaseValid}>
        A <b>lowercase</b> letter
      </ValidationField>
      <ValidationField valid={upperCaseValid}>
        A <b>capital (uppercase)</b> letter
      </ValidationField>
      <ValidationField valid={numberValid}>
        A <b>number</b>
      </ValidationField>
      <ValidationField valid={lengthValid}>
        Minimum <b>8 characters</b>
      </ValidationField>
    </MessageBox>
  );
};

const hasValidationError = (validationErrors = {}) => {
  return Object.values(validationErrors).some((valid) => !valid);
};

export const SignUp = ({
  onFormChange,
  form,
  validationErrors,
  onSignUp,
  onBlur,
  onFocus,
}) => {
  const [passwordFocused, setPasswordFocused] = useState(false);
  const onPasswordFocus = () => setPasswordFocused(true);
  const onPasswordBlur = () => setPasswordFocused(false);
  const isInvalid = hasValidationError(validationErrors[PASSWORD_FIELD]);

  return (
    <LoginWrapper>
      <LoginOutBox>
        <Title>Sign up</Title>
        <ValidateForm>
          <LoginInput
            type="email"
            onChange={onFormChange}
            value={form.email}
            name="email"
            placeholder={"E-mail"}
            data-testid="@sign_up/email_input"
          />
          <LoginInput
            type="text"
            value={form.username}
            onChange={onFormChange}
            name="username"
            placeholder={"Username"}
            data-testid="@sign_up/username_input"
          />
          <LoginInput
            onFocus={onPasswordFocus}
            onBlur={onPasswordBlur}
            type="password"
            onChange={onFormChange}
            value={form.password}
            name={PASSWORD_FIELD}
            placeholder={"Password"}
            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            data-testid="@sign_up/password_input"
          />
          <LoginInput
            type="text"
            onChange={onFormChange}
            value={form.phone}
            name="phone"
            placeholder={"Telephone number"}
            data-testid="@sign_up/phone_input"
          />

          <PasswordMessageBox
            focused={passwordFocused}
            validationErrors={validationErrors[PASSWORD_FIELD]}
          />

          <ButtonContainer
            type="button"
            name="button"
            onClick={onSignUp}
            disabled={isInvalid}
            data-testid="@sign_up/submit_button"
          >
            Sign up
          </ButtonContainer>
        </ValidateForm>
      </LoginOutBox>
    </LoginWrapper>
  );
};

export default SignUp;
