import styled from "styled-components";
import { colors } from "./Tokens";

export const StyledButtonContainer = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 10rem;
  padding-right: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.pink};
`;

export const ButtonContainer = styled.button`
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 50px;

  font-size: 15px;
  color: #fff;
  line-height: 1.2;

  border-radius: 8px;
  background-color: #000;
  padding-right: 20px;
  padding-left: 20px;
  &:hover {
    background: white;
    color: red;
    /* cursor: pointer; */
  }
  &:focus {
    outline: none;
  }
`;

export const ButtonStart = styled.button`
  justify-content: center;
  -ms-align-items: center;
  align-items: center;

  width: 250px;
  height: 80px;

  font-size: 25px;
  color: #aa2c62;
  line-height: 1.2;

  border-radius: 15px;
  background-color: white;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
  }
  &:focus {
    outline: none;
  }
`;

export const QuestionButton = styled.button`
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  width: 200px;
  height: 80px;
  left: 37%;
  top: 70%;

  font-size: 20px;
  color: #aa2c62;
  line-height: 1.2;

  border-radius: 15px;
  border: 3px solid white;
  color: white;
  background-color: transparent;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.4);
  }
  &:focus {
    outline: none;
  }
`;

export default ButtonContainer;
