import styled from "styled-components";
import { colors, radius } from "./Tokens";

const StyledTextArea = styled.textarea<{ size: number }>`
  background-color: #fff;
  width: 100%;
  font-size: 2.4rem;
  color: ${colors.purple};
  border-style: solid;
  border-width: 0.2rem;
  border-radius: ${radius.small};
  border-color: #fff;
  outline: none;
  height: 12rem;
  padding: 1.6rem;
  font-weight: 400;
  width: ${({ size }) => size + "rem"};

  ${(props) => {
    if (props.color === "white") {
      return `
        background-color: ${colors["grey-light"]};
        border-color: ${colors["grey-light"]};
      `;
    }
  }}

  &:placeholder-shown {
    color: ${colors.grey};
  }

  &:active,
  &:focus {
    border-color: ${colors.pink};
    color: ${colors.pink};
  }
`;

export const TextArea = ({
  color,
  children,
  ...props
}: React.ComponentProps<typeof StyledTextArea>) => {
  return (
    <StyledTextArea color={color} {...props}>
      {children}
    </StyledTextArea>
  );
};
