import styled from "styled-components";

import { widths } from "./Tokens";

export const Row = styled.div<{
  valign: "top" | "bottom" | "center";
}>`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  margin: 0;
  gap: 20px;
  align-items: ${({ valign }) => {
    if (valign === "top") {
      return "flex-start";
    } else if (valign === "bottom") {
      return "flex-end";
    } else if (!valign) {
      return "normal";
    } else {
      return valign;
    }
  }};
`;

export const CustomWidth = styled.div<{
  width: number;
}>`
  flex: 0 0 ${({ width }) => width};
  max-width: ${({ width }) => width};
  padding: 0 2rem;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const AutoWidth = styled.div`
  flex: 1 1 0%;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 768px) {
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;

export const FullWidth = styled.div`
  width: ${widths.full};
  flex: 0 0 ${widths.full};
  min-width: ${widths.full};
  padding: 0 2rem;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const TwoThirdWidth = styled.div`
  width: ${widths.full};
  flex: 0 0 ${widths.full};
  min-width: ${widths.full};
  padding: 1rem 2rem;

  @media screen and (min-width: 768px) {
    width: ${widths.twothird};
    flex: 0 0 ${widths.twothird};
    min-width: ${widths.twothird};
    padding: 1rem 0;
  }
`;

export const OneThirdWidth = styled.div`
  width: ${widths.full};
  flex: 0 0 ${widths.full};
  min-width: ${widths.full};
  padding: 1rem 0;

  @media screen and (min-width: 768px) {
    width: ${widths.third};
    flex: 0 0 ${widths.third};
    min-width: ${widths.third};
    padding: 1rem 2rem;
  }
`;

export const HalfWidth = styled.div`
  width: ${widths.full};
  flex: 0 0 ${widths.full};
  min-width: ${widths.full};
  padding: 1rem 0;

  @media screen and (min-width: 768px) {
    width: ${widths.half};
    flex: 0 0 ${widths.half};
    min-width: ${widths.half};
    padding: 1rem 2rem;
  }
`;
