import { useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GenerateInvoice } from "./Invoice";

export function InvoiceGenerator({ state, assembly, onGenerate }) {
  const { user } = useAuth0();
  const hasGenerated = useRef(false); // Ref to track invoice generation

  useEffect(() => {
    // Ensure invoice generation happens only once
    if (user && onGenerate && !hasGenerated.current) {
      GenerateInvoice(state, assembly, user); 
      onGenerate(); // Reset the generateInvoice flag after generating the invoice
      hasGenerated.current = true; // Set ref to true, preventing further generations
    }
  }, [user, onGenerate, state, assembly]);

  return null; // This component doesn't render anything
}